/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import autodeskInstagramAndTheFffiddleHeroImage from "../autodesk-instagram-and-the-fffiddle/hero.jpg";
import deductiveComponentLanguageHeroImage from "../deductive-component-language/hero.jpg";
import doSpaceHeroImage from "../do-space/hero.jpg";
import hovalinAtNasaHeroImage from "../hovalin-at-nasa/hero.jpg";
import hovalinAuctionHeroImage from "../hovalin-auction/hero.jpg";
import hovalinFiveHeroImage from "../hovalin-five/hero.jpg";
import hovalinThreeOhPlanHeroImage from "../hovalin-three-oh-plan/hero.jpg";
import hovalinTwoHeroImage from "../hovalin-two/hero.jpg";
import oatmealPancakesHeroImage from "../oatmeal-pancakes/hero.jpg";
import openscadALoveStoryHeroImage from "../openscad-a-love-story/hero.jpg";
import ourFiveStringSynesthesiaViolinHeroImage from "../our-five-string-synesthesia-violin/hero.jpg";
import steamCarnivalSfHeroImage from "../steam-carnival-sf/hero.jpg";
import theBestChickenMarinadeRecipeHeroImage from "../the-best-chicken-marinade-recipe/hero.jpg";
import theSiteIsNowLiveHeroImage from "../the-site-is-now-live/hero.jpg";
import womenWhoCodeHeroImage from "../women-who-code/hero.jpg";

export const blogManifest = [
  {
    hero: hovalinFiveHeroImage,
    datePublished: "2020-09-07T00:00:00.000Z",
    tags: ["Hovalin", "CAD"],
    title: "Hovalin V5",
    subtitle: "Now compatible with even more printers!",
    url: "/blog/hovalin-five",
  },
  {
    hero: theBestChickenMarinadeRecipeHeroImage,
    datePublished: "2020-05-16T00:00:00.000Z",
    tags: ["Recipe", "Dinner", "Grilling"],
    title: "The Best Chicken Marinade Recipe",
    subtitle: "we love it, and think you will too",
    url: "/blog/the-best-chicken-marinade-recipe",
  },
  {
    hero: oatmealPancakesHeroImage,
    datePublished: "2020-05-10T00:00:00.000Z",
    tags: ["Recipe", "Breakfast"],
    title: "Oatmeal Pancakes",
    subtitle: "Kick your breakfast off with some tasty pancakes",
    url: "/blog/oatmeal-pancakes",
  },
  {
    hero: deductiveComponentLanguageHeroImage,
    datePublished: "2020-03-01T00:00:00.000Z",
    tags: ["Design-Systems"],
    title: "Deductive Component Language",
    subtitle: "Streamline Product, Design, and Engineering!",
    url: "/blog/deductive-component-language",
  },
  {
    hero: ourFiveStringSynesthesiaViolinHeroImage,
    datePublished: "2020-02-12T16:26:08.712Z",
    tags: ["Electronics", "Hovalin"],
    title: "Our 5 String Synesthesia Violin",
    subtitle: "See the colors of sound in real time",
    url: "/blog/our-five-string-synesthesia-violin",
  },
  {
    hero: hovalinThreeOhPlanHeroImage,
    datePublished: "2016-04-18T06:10:44.160Z",
    tags: ["Hovalin"],
    title: "HOVALIN 3.0 PLAN",
    subtitle: "We're never finished",
    url: "/blog/hovalin-three-oh-plan",
  },
  {
    hero: hovalinTwoHeroImage,
    datePublished: "2016-01-06T06:10:44.160Z",
    tags: ["Hovalin"],
    title: "HOVALIN V2.0 IS LIVE",
    subtitle: "We did it",
    url: "/blog/hovalin-two",
  },
  {
    hero: hovalinAuctionHeroImage,
    datePublished: "2016-01-02T06:10:44.160Z",
    tags: ["Hovalin"],
    title: "HOVALIN AUCTION",
    subtitle: "Doing some good",
    url: "/blog/hovalin-auction",
  },
  {
    hero: doSpaceHeroImage,
    datePublished: "2015-12-24T06:10:44.160Z",
    tags: ["Hovalin"],
    title: "DO SPACE",
    subtitle: "A really cool organization that's excited about the Hovalin",
    url: "/blog/do-space",
  },
  {
    hero: hovalinAtNasaHeroImage,
    datePublished: "2015-12-05T00:00:00.000Z",
    tags: ["Hovalin"],
    title: "HOVALIN AT NASA",
    subtitle: "Not sure how we got here but we're delighted",
    url: "/blog/hovalin-at-nasa",
  },
  {
    hero: theSiteIsNowLiveHeroImage,
    datePublished: "2015-11-28T00:00:00.000Z",
    tags: [],
    title: "THE SITE IS NOW LIVE!",
    subtitle: "Yes, finally",
    url: "/blog/the-site-is-now-live",
  },
  {
    hero: womenWhoCodeHeroImage,
    datePublished: "2015-11-18T00:00:00.000Z",
    tags: ["Hovalin", "Software"],
    title: "WOMEN WHO CODE",
    subtitle: "A great nonprofit that empowers women in tech",
    url: "/blog/women-who-code",
  },
  {
    hero: steamCarnivalSfHeroImage,
    datePublished: "2015-11-16T00:00:00.000Z",
    tags: ["Hovalin", "Events"],
    title: "STEAM CARNIVAL SF",
    subtitle: "So much fun and so much STEAM",
    url: "/blog/steam-carnival-sf",
  },
  {
    hero: openscadALoveStoryHeroImage,
    datePublished: "2015-09-25T00:00:00.000Z",
    tags: ["Hovalin", "CAD"],
    title: "OpenSCAD... a love story",
    subtitle: "For alpha nerds",
    url: "/blog/openscad-a-love-story",
  },
  {
    hero: autodeskInstagramAndTheFffiddleHeroImage,
    datePublished: "2015-09-02T00:00:00.000Z",
    tags: ["Hovalin"],
    title: "AUTODESK, INSTAGRAM, AND THE FFFIDDLE",
    subtitle: "Origins of the Hovalin",
    url: "/blog/autodesk-instagram-and-the-fffiddle",
  },
];
